@import "_settings/globalSetting";
@import "./product/detail";
@import "./product/recommendedGrid";
@import "pdp/sizeguide";
@import "./utilities/swatch";
@import 'lib/iconfont/iconfont-build';
@import "./product/originalAndLowestPrice";
@import "./components/modalBackInStock";

html,
body {
    overflow: visible !important;

    .pac-container {
        z-index: 10000 !important;
    }
}

body {
    &.modal-open {
        overflow: hidden !important;
    }
}

.cc-breadcrumb {
    color: $cold-grey-05;
    display: inline-block;
    font-size: rem-calc(13);
    font-weight: $primary-font-regular;
    line-height: rem-calc(18);

    &.cc-breadcrumb {
        &:first-child {
            margin-left: 0;
            padding-left: 0;
        }
    }

    &:hover {
        color: $red;
    }

    a {
        vertical-align: super;
    }

    &--container {
        display: flex;
        flex-direction: row;
        padding: rem-calc(10) 0 rem-calc(12);
    }

    &--icon {
        display: inline-block;
        height: rem-calc(16);
        margin: 0 rem-calc(7);
        width: rem-calc(16);
        background-image: url('../images/arrow-dropdown-right.svg');
        background-size: contain;
        background-repeat: no-repeat;
    }

    &--last {
        color: $red;
    }
}

.cc-product-detail {

    &.container {
        max-width: unset;
        padding: 0 rem-calc(16);

        @include media-breakpoint-up(md) {
            padding: 0 rem-calc(24);
        }
    }

    .cc-product-breadcrumb {
        &--desktop {
            display: none;
            position: relative;
            z-index: 2;
            margin-bottom: rem-calc(-42);

            @include media-breakpoint-up(sm) {
                display: block;
            }
        }

        &--mobile {
            display: block;

            @include media-breakpoint-up(sm) {
                display: none;
            }
        }

        .col {
            padding: 0;
        }

        .cc-breadcrumb-container {
            display: flex;
            align-items: center;
            overflow-x: auto;
            white-space: nowrap;
            gap: rem-calc(6);
            padding: rem-calc(12) 0;
            margin: 0;

            .cc-breadcrumb {
                list-style-type: none;
                font-size: rem-calc(13);
                line-height: rem-calc(18);
                letter-spacing: rem-calc(0.13);
                margin: 0;
                padding: 0;

                &--active {
                    color: $red;
                }
            }

            .cc-breadcrumb-icon {
                display: block;
                width: rem-calc(16);
                height: rem-calc(16);

                &:last-child {
                    display: none;
                }
            }
        }
    }

    .cc-pdp-row {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        @include media-breakpoint-up(sm) {
            flex-direction: row;
        }

        .cc-pdp-col-images {
            width: 100%;

            @include media-breakpoint-up(sm) {
                width: 50%;
            }

            @include media-breakpoint-up(md) {
                width: 65%;
            }

            .cc-pdp-carousels-container {
                display: flex;
                flex-direction: row;
                position: relative;
                margin: 0 rem-calc(-16);
                font-size: 0;

                @include media-breakpoint-up(sm) {
                    margin: rem-calc(-61) 0 0 rem-calc(-16);
                }

                @include media-breakpoint-up(md) {
                    margin: rem-calc(-61) 0 0 rem-calc(-24);
                }

                .slick-dots {
                    position: absolute;
                    top: auto;
                    bottom: rem-calc(16);
                    left: rem-calc(16);
                    right: auto;
                    width: auto;

                    li {
                        @include animation();
                        position: relative;
                        width: rem-calc(2);
                        height: rem-calc(2);
                        border-radius: rem-calc(2);
                        margin: 0 rem-calc(4) 0 0;
                        padding: 0;
                        background: $warm-grey-04;

                        &.slick-active {
                            background: $warm-grey-06;
                            width: rem-calc(30);
                        }

                        button {
                            opacity: 0;
                            display: block;
                            width: 100%;
                            height: 100%;
                            margin: 0;
                            padding: 0;
                            border: none;
                        }
                    }
                }

                &.zoom-active {
                    .cc-container-zoom-icon,
                    .cc-pdp-images-carousel-arrow {
                        display: none;
                    }

                    .cc-pdp-images-carousel {
                        .cc-pdp-carousel-item {
                            .cc-container-dis-picture {
                                cursor: zoom-out;
                                cursor: url('../images/maximize.svg'), auto;
                            }
                        }
                    }
                }

                .cc-container-zoom-icon {
                    position: absolute;
                    bottom: rem-calc(16);
                    right: rem-calc(16);
                    background: $white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: rem-calc(32);
                    height: rem-calc(32);
                    cursor: pointer;

                    @include media-breakpoint-up(sm) {
                        display: none;
                    }

                    .cc-icon-maximize {
                        display: block;
                        width: rem-calc(20);
                        height: rem-calc(20);

                        @include media-breakpoint-up(lg) {
                            display: none;
                        }
                    }
                }

                .cc-pdp-images-carousel {
                    width: 100%;
                    margin: 0;

                    @include media-breakpoint-up(sm) {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        gap: rem-calc(1);
                    }

                    .cc-pdp-carousel-item {
                        display: block;
                        cursor: pointer;
                        width: 100%;
                        flex: 0 0 100%;

                        @include media-breakpoint-up(sm) {
                            width: calc(50% - #{rem-calc(1)});
                            flex: 0 0 calc(50% - #{rem-calc(1)});
                        }

                        &--single {
                            @include media-breakpoint-up(sm) {
                                width: 100%;
                                flex: 0 0 100%;
                            }
                        }

                        .cc-container-dis-picture {
                            cursor: zoom-in;
                            cursor: url('../images/maximize.svg'), auto;
                        }

                        img {
                            height: auto;
                            max-height: 100%;
                            object-fit: cover;
                            width: 100%;
                            transform: none!important;
                        }

                        &.video {
                            cursor: zoom-out;
                            cursor: url('../images/maximize.svg'), auto;
                        }

                        iframe {
                            aspect-ratio: 1 / 1;
                            pointer-events: none;
                        }
                    }
                }

                .cc-pdp-ar-try-on-iframe {
                    margin: 0 auto;
                    height: rem-calc(480);
                    width: 100%;

                    @include media-breakpoint-down(xs) {
                        height: rem-calc(400);
                    }

                    #ar-try-on-iframe {
                        width: 100%;
                        height: 100%;
                    }
                }

                .cc-pdp-carousel-thumbnails {

                    @include media-breakpoint-down(xs) {
                        display: none;
                    }

                    @include media-breakpoint-up(sm) {
                        margin-right: rem-calc(10);
                        text-align: center;
                        width: rem-calc(40);
                    }

                    @include media-breakpoint-up(md) {
                        margin-right: rem-calc(20);
                        width: rem-calc(80);
                    }

                    .cc-pdp-thumbnails-carousel-item {
                        img {
                            cursor: pointer;
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }
        }

        //IN-AR TRY ON
        .cc-try-on-container-mobile {
            display: none;

            @include media-breakpoint-down(xs) {
                margin: rem-calc(16) auto 0;
                display: block;
            }

            .cc-try-on-ar-button {
                cursor: pointer;
            }

            .cc-try-on-ar-image {
                width: 70%;
            }
        }

        .cc-pdp-col-description {
            width: 100%;

            @include media-breakpoint-up(sm) {
                padding-left: rem-calc(32);
                width: 50%;
            }

            @include media-breakpoint-up(md) {
                padding-left: rem-calc(64);
                width: 35%;
            }

            @include media-breakpoint-up(lg) {
                padding-right: rem-calc(40);
            }

            &__wrp {
                padding-top: rem-calc(24);

                @include media-breakpoint-up(sm) {
                    @include animation();
                    position: sticky;
                    top: rem-calc(64);
                    padding-top: rem-calc(32);
                }
            }

            .cc-product-label {
                position: relative;
                top: auto;
                right: auto;
                font-size: rem-calc(12);
                font-style: normal;
                font-weight: 800;
                line-height: rem-calc(18);
                letter-spacing: rem-calc(1.2);
                text-transform: uppercase;
                margin-bottom: rem-calc(9);

                &__brand {
                    color: $black-warm;

                    &:hover {
                        color: $red;
                    }
                }

                &__badge {
                    &::before {
                        display: inline-block;
                        color: $black-warm;
                        content: '✦';
                        font-size: rem-calc(12);
                        font-style: normal;
                        font-weight: 800;
                        line-height: rem-calc(18);
                        letter-spacing: rem-calc(1.2);
                        margin: 0 rem-calc(8);
                    }
                }
            }

            .cc-brand-img {
                margin-bottom: rem-calc(16);
                margin-top: rem-calc(12);

                @include media-breakpoint-up(sm) {
                    margin-top: rem-calc(65);
                }

                img {
                    max-height: 2.5rem;
                    max-width: 7rem;
                    width: auto;
                    height: auto;
                }

                .long-logo {
                    max-width: 10rem;
                }

                .short-logo {
                    max-width: 6.5rem;
                }
            }

            .cc-badge {
                display: block;
                font-size: rem-calc(10);
                line-height: rem-calc(14);
                letter-spacing: rem-calc(1);
                font-weight: $primary-font-extra-bold;
                color: $red;
                margin: 0 0 rem-calc(12);

                @include media-breakpoint-up(sm) {
                    margin-bottom: rem-calc(16);
                }

                .cc-dis-loader-img {
                    display: none;
                }

                .cc-label-newin {
                    color: $black;
                    text-decoration: underline;
                }

                .cc-label-clubprice {
                    color: $red;

                    &::before {
                        font-size: rem-calc(65);
                    }
                }

                .cc-container-dis-picture {
                    img {
                        max-height: rem-calc(50);
                        width: auto;
                    }
                }

            }

            .cc-content-prod-name {
                position: relative;
                padding-right: rem-calc(28);
            }

            .cc-add-to-wish-list {
                position: absolute;
                top: 0;
                right: 0;
                width: rem-calc(20);
                height: rem-calc(20);

                .cc-wishlist-icon {
                    display: block;
                    width: rem-calc(20);
                    height: rem-calc(20);

                    &:hover {
                        color: $red;
                    }
                }
            }

            .cc-label-custom {
                img {
                    display: inline-block;
                    width: auto;
                    height: rem-calc(18);
                    object-fit: contain;
                }
            }

            .cc-pdp-product-name {
                display: block;
                font-weight: $primary-font-medium;
                font-size: rem-calc(18);
                line-height: rem-calc(24);
                margin-bottom: rem-calc(4);
                color: $black;

                @include media-breakpoint-up(sm) {
                    margin-bottom: rem-calc(8);
                }
            }

            .cc-pdp-prices {
                font-size: rem-calc(18);
                line-height: rem-calc(24);
                margin-bottom: rem-calc(32);

                @include media-breakpoint-up(sm) {
                    margin-bottom: rem-calc(48);
                }

                .cc-price {
                    display: inline-block;
                    font-weight: $primary-font-bold;
                    margin: 0;

                    &--discount {
                       display: none;
                       font-weight: 400;
                       color: $red;
                       margin-left: rem-calc(3);
                    }
                }

                .cc-pp-price-container {
                    margin-top: rem-calc(8);

                    @include media-breakpoint-up(sm) {
                        margin-top: rem-calc(15);
                    }
                }

                .cc-original-price-container {
                    margin-top: rem-calc(8);

                    @include media-breakpoint-up(sm) {
                        margin-top: rem-calc(12);
                    }
                }

                .cc-pp-price-container + .cc-original-price-container {
                    margin-top: 0;
                }

                .cc-price-reduced {
                    display: none;
                    font-weight: $primary-font-regular;
                    margin-left: rem-calc(4);
                    position: relative;
                    text-decoration: line-through;

                    &.cc-price-reduced-mrp {
                        margin-left: 0;
                        margin-right: 0;
                        padding-left: 0;
                        padding-right: 0;
                    }
                }

                @include original-and-pp-price-container(13, 13, 20, 3, flex-start);

            }

            .cc-2c2p-bnpl {

                .cc-bnpl-price {
                    font-weight: $primary-font-semibold;
                }
            }

            .pdp-promotions {
                margin-bottom: rem-calc(49);
                margin-top: rem-calc(-16);


                @include media-breakpoint-up(lg) {
                    margin-bottom: rem-calc(42);
                    margin-top: rem-calc(-32);
                }

                .cc-plp-promo {
                    display: none;
                }
            }

            .cc-attributes {
                padding: 0;

                .cc-color-selected {
                    font-size: 0;
                    text-align: left;
                    margin: 0 0 rem-calc(12);

                    span {
                        display: inline-block;
                        vertical-align: middle;
                        font-weight: $primary-font-medium;
                        font-size: rem-calc(12);
                        line-height: rem-calc(18);
                        letter-spacing: rem-calc(1.2);
                        text-transform: uppercase;
                        color: $black;
                        margin: 0;

                        &:first-child {
                            color: $warm-grey-05;
                            margin-right: rem-calc(8);
                        }
                    }
                }

                .cc-swatches-container {
                    font-size: 0;
                    margin-top: rem-calc(10);
                    margin-bottom: rem-calc(32);
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    gap: rem-calc(8);

                    @include media-breakpoint-up(sm) {
                        margin-bottom: rem-calc(48);
                    }

                    .cc-color-attribute {
                        border: rem-calc(1) solid transparent;
                        border-radius: rem-calc(2);

                        &.cc-imageAttribute {
                            width: rem-calc(48);
                            height: rem-calc(48);
                            margin: 0;
                        }

                        .cc-container-dis-picture img {
                            @include animation();
                        }

                        &.cc-circleAttribute {
                            border-radius: rem-calc(50);
                        }

                        .cc-swatch-circle {
                            @include swatch(24px, $white);
                            display: flex;
                            // height: rem-calc(24);
                            margin: rem-calc(6);
                            // width: rem-calc(24);

                            &.miscellaneous {
                                background: #e5e5e5;
                                background-image: linear-gradient(270deg, #0400D0 0%, #FED600 50%, #E00 100%);
                            }

                            &.animal {
                                background-color: #b99f84;
                                background-image: radial-gradient(50% 50% at 50% 50%, #705D50 0%, #A3723A 100%);
                            }

                            &.zebra {
                                background-color: $white-warm;
                                background-image: repeating-linear-gradient(135deg, $white-warm 0 #{rem-calc(3)}, $black #{rem-calc(3)} #{rem-calc(5)});
                            }

                            &.white-green {
                                background: $white-warm;
                                background-image: linear-gradient(135deg, $white-warm 50%, $warm-green 51%);
                                border: rem-calc(1) solid $warm-grey-01;
                            }

                            &.white-blue {
                                background: $white-warm;
                                background-image: linear-gradient(135deg, $white-warm 50%, #003395 51%);
                                border: rem-calc(1) solid $warm-grey-01;
                            }

                            &.white-gold {
                                background: $white-warm;
                                background-image: linear-gradient(135deg, $white-warm 50%, #BBA63B 51%, #554B1B 100%);
                                border: rem-calc(1) solid $warm-grey-01;
                            }

                            &.white-silver {
                                background: $white-warm;
                                background-image: linear-gradient(135deg, $white-warm 50%, #F0F0F0 51%, #8A8A8A 100%);
                                border: rem-calc(1) solid $warm-grey-01;
                            }
                        }

                        &:hover {
                            border-color: $warm-grey-03;

                            .cc-container-dis-picture img {
                                padding: rem-calc(4);
                            }
                        }

                        &.selected {
                            border-color: $black;

                            .cc-container-dis-picture img {
                                padding: rem-calc(4);
                            }
                        }

                        &.unselectable {
                            border-color: $grey2;
                            cursor: not-allowed;
                            position: relative;

                            &::after {
                                background-color: $grey2;
                                content: '';
                                height: rem-calc(1);
                                left: 0;
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%) rotate(-45deg);
                                width: 100%;
                                z-index: 1;
                            }
                        }
                    }
                }

                .cc-size-label-container {
                    align-items: center;
                    display: flex;
                    justify-content: space-between;
                    margin: 0 0 rem-calc(12);

                    .cc-size-selected {
                        font-size: 0;
                        text-align: left;
                        margin: 0;

                        span {
                            display: inline-block;
                            vertical-align: middle;
                            font-weight: $primary-font-medium;
                            font-size: rem-calc(12);
                            line-height: rem-calc(18);
                            letter-spacing: rem-calc(1.2);
                            text-transform: uppercase;
                            color: $black;
                            margin: 0;

                            &:first-child {
                                color: $warm-grey-05;
                                margin-right: rem-calc(8);
                            }

                            &.cc-size-value {
                                display: none;
                            }
                        }
                    }

                    .cc-size-guide-asset-container {
                        font-size: rem-calc(12);

                        .cc-size-guide-not-found {
                            color: $red1;
                        }

                        .cc-size-guide-asset-link {
                            font-size: rem-calc(12);
                            line-height: rem-calc(18);
                            letter-spacing: rem-calc(1.2);
                            text-transform: uppercase;
                            font-weight: $primary-font-medium;
                            color: $black;
                            text-align: right;
                            text-decoration: none;
                            display: block;

                            .cc-sizeguide-icon {
                                transition: none;
                                display: inline;
                                width: rem-calc(12);
                                height: rem-calc(12);
                            }

                            &:hover {
                                @include media-breakpoint-up(md) {
                                   color:$red;

                                   .cc-sizeguide-icon {
                                        stroke:$red;
                                    }
                                }
                            }
                        }
                    }
                }

                .cc-container-size-list {
                    margin-bottom: 0;

                    .cc-size-ul-list {
                        display: flex;
                        flex-wrap: wrap;
                        gap: rem-calc(5);
                        margin-right: rem-calc(-4);

                        .cc-single-size-tile {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border: rem-calc(1) solid $warm-grey-02;
                            border-radius: rem-calc(2);
                            cursor: pointer;
                            margin: 0;
                            height: rem-calc(40);
                            font-weight: $primary-font-medium;
                            font-size: rem-calc(15);
                            line-height: rem-calc(22);
                            padding: 0 0.4rem;
                            width: calc(33.333333% - #{rem-calc(4)});

                            @include media-breakpoint-up(lg) {
                                width: calc(25% - #{rem-calc(4)});
                            }

                            &:hover {
                                @include media-breakpoint-up(lg) {
                                    color: $red;

                                    svg {
                                        fill: $red;
                                    }
                                }
                            }

                            &.selected {
                                border-color: $black;
                                position: relative;
                            }

                            &.unselectable {
                                border-color: $grey2;
                                color: $grey2;
                                cursor: not-allowed;
                                position: relative;
                            }

                            &.low-stock {
                                position: relative;
                                &::after {
                                    background-color: $red;
                                    border-radius: rem-calc(2);
                                    bottom: rem-calc(9);
                                    content: '';
                                    height: rem-calc(4);
                                    position: relative;
                                    width: rem-calc(4);
                                    display: inline-block;
                                }
                            }

                            .frac {
                                font-feature-settings: "frac";
                                padding-left: 3px;
                            }

                            .cc-back-in-stock__message {
                                display: none;
                            }

                            &.b-backInStock {
                                position: relative;
                                .cc-back-in-stock__message {
                                    @include media-breakpoint-up (lg) {
                                        align-items: flex-start;
                                        background-color: $white;
                                        border-radius: rem-calc(2);
                                        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.20);
                                        flex-direction: column;
                                        gap: rem-calc(4);
                                        justify-content: center;
                                        padding: rem-calc(12) rem-calc(16);
                                        position: absolute;
                                        top: calc(100% + 7px);
                                        opacity: 0;
                                        transition: 300ms;
                                        width: rem-calc(200);
                                        z-index: 1;
                                        &__title {
                                            color: $black-warm;
                                            font-size: rem-calc(13);
                                            font-weight: $primary-font-bold;
                                            line-height: rem-calc(18);
                                        }
                                        &__text {
                                            color: $black-warm;
                                            font-size: rem-calc(13);
                                            font-weight: $primary-font-regular;
                                            letter-spacing: rem-calc(0.13);
                                            line-height: rem-calc(18);
                                            margin-bottom: 0;
                                            text-align: center;
                                            display: block;
                                            width: 100%;
                                        }
                                        &::after {
                                            content: '';
                                            border-left: rem-calc(12.5) solid transparent;
                                            border-right: rem-calc(12.5) solid transparent;
                                            border-bottom:rem-calc(8) solid $white;
                                            display: block;
                                            height: 0;
                                            position: absolute;
                                            top: rem-calc(-6);
                                            width: 0;
                                            left: 50%;
                                            transform: translateX(-50%);
                                        }
                                    }
                                }
                                &:hover {
                                    .cc-back-in-stock__message {
                                        display: flex;
                                        opacity: 1;
                                        transition: 300ms;
                                        @include media-breakpoint-down (lg) {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .js-scalapay-widget {
                    font-size: rem-calc(13);
                    line-height: rem-calc(18);
                    letter-spacing: rem-calc(0.13);
                    color: $black;
                    margin: 0 0 rem-calc(12);
                }

                .cc-product-availability {
                    .cc-availability-msg {
                        color: $warm-green;
                        font-size: rem-calc(13);
                        line-height: rem-calc(18);
                        letter-spacing: rem-calc(0.13);
                        font-weight: $primary-font-medium;
                        margin-top: rem-calc(12);
                        margin-bottom: rem-calc(32);
                        min-height: rem-calc(1);

                        @include media-breakpoint-up(sm) {
                            margin-bottom: rem-calc(36);
                        }

                        .b-pdp__availability {
                            color: $red;
                            font-size: rem-calc(13);
                            font-weight: $primary-font-medium;
                            letter-spacing: rem-calc(0.13);
                            line-height: rem-calc(18);
                        }
                    }
                }

                .cc-add-more-info {
                    color: $warm-grey-05;
                    font-size: rem-calc(13);
                    font-weight: $primary-font-regular;
                    line-height: rem-calc(18);
                    letter-spacing: rem-calc(0.13);
                    margin: 0 0 rem-calc(12);
                }

                .prices-add-to-cart-actions {
                    .add-to-cart {
                        margin: 0 0 rem-calc(13);
                    }

                    .cc-pdp__findinstore {
                        font-size: rem-calc(12);
                        line-height: rem-calc(18);
                        letter-spacing: rem-calc(1.2);
                        color: $black;
                        font-weight: $primary-font-medium;
                        text-transform: uppercase;
                        width: auto;
                        height: auto;
                        margin: 0;
                        padding: 0;
                        border: none;
                        border-bottom: rem-calc(1) solid $black;

                        &:hover {
                            @include media-breakpoint-up(md) {
                                color: $red;
                                border-bottom: rem-calc(1) solid $red;
                            }
                        }
                    }

                    //IN-AR TRY ON
                    .cc-try-on-container-desktop {
                        display: block;

                        @include media-breakpoint-down(xs) {
                            display: none;
                        }

                        .cc-try-on-ar-button {
                            cursor: pointer;
                        }

                        .cc-try-on-ar-image {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }

                .cc-edd-form {
                    padding-top: rem-calc(5);

                    .cc-form-group {
                        @include media-breakpoint-down(md) {
                            padding-bottom: 0;
                            margin-bottom: 0;
                        }
                    }

                    .edd-form-title {
                        display: block;
                        color: $warm-grey-05;
                        font-size: rem-calc(13);
                        font-weight: 500;
                        line-height: rem-calc(18);
                        letter-spacing: rem-calc(1.2);
                        text-transform: uppercase;
                        margin-top: rem-calc(43);

                        @include media-breakpoint-down(md) {
                            margin-top: rem-calc(26);
                        }
                    }

                    .cc-edd--message {
                        color: $warm-grey-05;
                        display: block;
                        font-size: rem-calc(13);
                        font-weight: 400;
                        line-height: rem-calc(18);
                        letter-spacing: rem-calc(0.13);
                        margin-bottom: rem-calc(10);

                        @include media-breakpoint-down(md) {
                            margin-bottom: rem-calc(14);
                        }
                    }

                    .cc-error-message {
                        margin-top: 0;
                    }

                    .cc-valid-message {
                        .cc-text-message {
                            color: $warm-green;
                            font-size: rem-calc(11);
                            font-weight: 400;
                            line-height: rem-calc(16);
                        }

                        .cc-valid-icon {
                            bottom: auto;
                            top: rem-calc(35);
                        }
                    }

                    .cc-content-input {
                        position: relative;

                        .cc-input {
                            display: block;
                            padding: rem-calc(7) rem-calc(80) rem-calc(7) rem-calc(15);
                            font-size: rem-calc(13);
                            font-weight: 400;
                            letter-spacing: rem-calc(0.13);
                            color: $black;
                            z-index: 1;

                            @include media-breakpoint-down(md) {
                                margin-bottom: 0;
                            }
                        }

                        .cc-content-button-input {
                            margin: 0;
                            position: absolute !important;
                            right: rem-calc(15);
                            top: 0;
                            z-index: 2;

                            .cc-btn-edd-search {
                                color: $black;
                                cursor: pointer;
                                display: block;
                                font-size: rem-calc(13);
                                font-weight: 500;
                                line-height: rem-calc(45);
                                margin: 0;
                                letter-spacing: rem-calc(0.13);
                                text-decoration: underline;
                                text-underline-offset: rem-calc(4);
                            }
                        }
                    }
                }

                .cc-social-wishlistbtn-container {
                    align-self: center;
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: rem-calc(16);
                    margin-top: rem-calc(20);

                    @include media-breakpoint-down(xs) {
                        align-items: center;
                        flex-direction: column;
                    }

                    .cc-add-to-wish-list {
                        .cc-add-wishlist {
                            position: relative;
                        }
                        .cc-add-wishlist,
                        .cc-remove-wishlist {
                            align-items: center;
                            display: flex;
                            font-size: rem-calc(14);
                            font-weight: $primary-font-medium;
                            justify-content: center;
                            text-transform: uppercase;

                            span {
                                &:first-child {
                                    font-size: rem-calc(20);
                                    margin-right: rem-calc(10);
                                }
                            }

                            &:hover {
                                color: $red1;
                            }
                        }

                        .cc-remove-wishlist {
                            span {
                                &:first-child {
                                    color: $red1;
                                }
                            }
                        }

                    }

                    .cc-social-container {
                        align-items: center;
                        display: flex;
                        justify-content: flex-end;
                        padding-top: rem-calc(15);

                        @include media-breakpoint-up(sm) {
                            padding-top: 0;
                        }

                        .cc-social-icons {
                            margin-right: rem-calc(-12);

                            li {
                                display: flex;
                                font-size: rem-calc(20);

                                a {
                                    padding-right: rem-calc(12);

                                    .cc-icon-twitter-container {
                                        background-color: $black;
                                        border-radius: rem-calc(50);
                                        font-size: rem-calc(30);
                                        height: rem-calc(20);
                                        position: relative;
                                        width: rem-calc(20);

                                        &:hover {
                                            background-color: #1da1f2;
                                        }

                                        span {
                                            color: $white;
                                            left: 50%;
                                            position: absolute;
                                            top: 50%;
                                            transform: translate(-50%, -50%);
                                        }
                                    }

                                    span {
                                        color: $black;

                                        &.icon-facebook-letter {
                                            &:hover {
                                                color: #2863ad;
                                            }
                                        }

                                        &.icon-pinterest {
                                            &:hover {
                                                color: #ba1621;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .cc-backInStock-icon {
                    height: rem-calc(20);
                    width: rem-calc(16);
                    margin-left: rem-calc(4);

                    &-menu {
                        stroke: currentColor;

                        &:hover {
                            stroke: $red;
                        }
                    }


                    &.cc-icon-search {
                        display: none;
                    }

                    &:hover {
                        color: $red !important;
                        fill: $red !important;
                    }
                }
            }
        }
    }

    .cc-pdp-details-section {
        padding: rem-calc(64) 0 rem-calc(40);

        @include media-breakpoint-up(lg) {
            padding: rem-calc(120) 0 rem-calc(70);
        }

        &__wrp {
            @include media-breakpoint-up(md) {
                display: flex;
                flex-direction: row;
                max-width: rem-calc(1220);
                margin: 0 auto;
            }
        }

        &__col {
            &--left {
                margin-bottom: rem-calc(64);

                @include media-breakpoint-up(md) {
                    width: calc(100% - #{rem-calc(480)});
                    flex: 0 0 calc(100% - #{rem-calc(480)});
                    padding-right: rem-calc(135);
                    margin: 0;
                }
            }

            &--right {
                @include media-breakpoint-up(md) {
                    width: rem-calc(480);
                    flex: 0 0 rem-calc(480);
                }
            }
        }
    }

    .cc-pdp-accordions {
        border: 0;
        border-radius: 0;
        margin-bottom: rem-calc(30);

        .cc-pdp-accordion-card {
            border: 0;
            border-bottom: rem-calc(1) solid $grey2;
            border-radius: 0;

            a {
                text-decoration: underline;

                &:hover {
                    @include media-breakpoint-up(md) {
                       color:$red;
                    }
                }
            }

            .cc-social-icons {
                display: flex;
                flex-direction: row;
                text-align: left;
                gap: rem-calc(24);

                @include media-breakpoint-down(md) {
                    flex-direction: column;
                    gap: rem-calc(12);
                }

                a {
                    display: flex;
                    flex-direction: row;
                    text-decoration: none;
                    color: $black;

                    &:hover {
                        @include media-breakpoint-up(md) {
                           color:$red;
                        }
                    }

                    .iconClassCommon {
                        transition: none;
                    }
                }

                .cc-social-text {
                    font-size: rem-calc(13);
                    line-height: rem-calc(18);
                    letter-spacing: rem-calc(0.13);
                    margin-left: rem-calc(4);

                    @include media-breakpoint-down(md) {
                        margin-left: rem-calc(8);
                    }
                }
            }

            .card-header {
                background: transparent;
                border: 0;
                padding: 0;

                .cc-accordion-header-title {
                    button {
                        align-items: center;
                        display: flex;
                        font-size: rem-calc(15);
                        line-height: rem-calc(22);
                        font-weight: $primary-font-bold;
                        justify-content: space-between;
                        padding: rem-calc(24) 0;
                        width: 100%;

                        .cc-pdp--accordion--icon {
                            transition: all 300ms;
                        }

                        &[aria-expanded="true"] {
                            .cc-pdp--accordion--icon {
                                transform: rotate(180deg);
                                transition: all 300ms;
                            }
                        }

                        &:hover {
                            @include media-breakpoint-up(lg) {
                                color: $red;
                            }
                        }
                    }
                }
            }

            .card-body {
                font-size: rem-calc(13);
                line-height: rem-calc(18);
                letter-spacing: rem-calc(0.13);
                padding: 0;
                padding-bottom: rem-calc(21);
                padding-top: 0;

                @include media-breakpoint-down(md) {
                    padding-bottom: rem-calc(24);
                }

                .cc-pdp-benefits-subtitle {
                    margin-bottom: rem-calc(15);

                    .cc-pdp-description-subtitle-label {
                        font-size: rem-calc(14);
                        font-weight: $primary-font-semibold;
                        margin-bottom: rem-calc(-20);
                        text-transform: uppercase;
                    }

                    .b-pdp_benefits-list {
                        white-space: pre-line;
                    }
                }

                .cc-pdp-technical-description {
                    margin-top: rem-calc(20);
                    margin-bottom: rem-calc(20);

                    .cc-pdp-technical-description-label {
                        font-size: rem-calc(14);
                        font-weight: $primary-font-semibold;
                        margin-bottom: rem-calc(10);
                        text-transform: uppercase;
                    }
                }

                .cc-description-attribute-label {
                    font-weight: $primary-font-bold;
                }

                .cc-pdp-material {
                    margin-top: rem-calc(2);

                    .cc-pdp-material-item {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: rem-calc(20);
                        max-width: 100%;
                        overflow: hidden;
                        padding-bottom: rem-calc(7);
                        margin-bottom: rem-calc(10);
                        border-bottom: rem-calc(1) dashed $warm-grey-02;

                        @include media-breakpoint-up(md) {
                            padding-bottom: rem-calc(13);
                            margin-bottom: rem-calc(12);
                        }

                        &:last-child {
                            margin-bottom: 0;
                            border-bottom: 0;
                        }

                        .cc-pdp__material-type {
                            width: 50%;

                            img {
                                width: rem-calc(30);
                                height: rem-calc(30);
                            }
                        }

                        .cc-pdp__material-type,
                        .cc-pdp__material-info {
                            width: 50%;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            flex: 50% 0 0;

                            .b-pdp__text {
                                display: inline-block;
                                font-size: rem-calc(13);
                                padding-left: rem-calc(8);
                                vertical-align: middle;
                                white-space: normal;
                                line-height: rem-calc(18);
                                letter-spacing: rem-calc(0.13);
                                max-width: 60%;
                            }

                            a {
                                text-decoration: underline;
                            }

                            img {
                                display: inline-block;
                                margin-right: 0;
                                vertical-align: middle;
                            }
                        }


                        .cc-pdp__material-info {
                            img {
                                width: rem-calc(26);
                                height: rem-calc(26);
                            }
                        }
                    }
                }

                .b-tabs_product-description {
                    display: flex;
                    flex-direction: column;
                    gap: rem-calc(8);
                }

                .b-pdp__description-marketedBy,
                .b-pdp__description-made-in,
                .b-pdp__description-manufacturer {
                    display: flex;
                    flex-direction: column;
                    gap: 0;
                }

                &.cc-card-body-asset {
                    .delivery-asset-title {
                        font-size: .875rem;
                        font-weight: $primary-font-semibold;
                        margin: 0;
                    }

                    ul {
                        margin-top: 1.25rem;

                        @include media-breakpoint-down(md) {
                            margin-top: rem-calc(11);
                        }

                        li {
                            display: list-item;
                            list-style: disc;
                            margin-left: rem-calc(18);
                        }
                    }
                }
            }
        }
    }

    .cc-modal-find-in-store {
        .modal-body-wrapper {
            overflow-y: auto;
            height: calc(100vh - 247px);
            -ms-overflow-style: none;  /* Internet Explorer 10+ */
            scrollbar-width: none;
            overflow: -moz-scrollbars-none;

            &::-webkit-scrollbar {
                display: none;  /* Safari and Chrome */
            }

            @include media-breakpoint-up(sm) {
                height: calc(100vh - 267px);
            }
        }

        .modal-dialog {
            width: 100%;
            height: 100%;
            margin: 0 0 0 auto;
            max-width: rem-calc(516);
            background: $white;
            overflow-y: hidden;

            .modal-content {
                border-radius: 0;
                height: 100%;
                border: none;
            }

            .cc-pdp-modal-header {
                border: 0;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding: rem-calc(20) rem-calc(16);
                border: none;
                margin-bottom: 0;

                @include media-breakpoint-up(sm) {
                    padding: rem-calc(32);
                }

                .cc-pdp-modal-header-title {
                    display: block;
                    font-weight: $primary-font-semibold;
                    font-size: rem-calc(22);
                    line-height: rem-calc(26);
                    color: $black;
                    margin: 0 auto 0 0;

                    @include media-breakpoint-up(sm) {
                        font-size: rem-calc(24);
                        line-height: rem-calc(28);
                    }
                }

                .cc-pdp-button-close {
                    font-size: rem-calc(21);
                    margin: 0 0 0 auto;

                    &:hover {
                        @include media-breakpoint-up(md) {
                            color: $red;
                        }
                    }
                }
            }

            .cc-find-store__description {
                font-size: rem-calc(13);
                line-height: rem-calc(18);
                letter-spacing: rem-calc(0.13);
                margin-bottom: rem-calc(16);
                padding: 0 rem-calc(16);
                text-align: left;

                @include media-breakpoint-up(sm) {
                    padding: 0 rem-calc(32);
                }

                @include media-breakpoint-up(md) {
                    font-size: rem-calc(15);
                    line-height: rem-calc(22);
                    letter-spacing: rem-calc(0.15);
                }

                p {
                    font: inherit;
                    margin: 0;
                }
            }

            .cc-form-group {
                margin-bottom: rem-calc(24);
                padding: 0 rem-calc(16) rem-calc(24);
                position: relative;

                @include media-breakpoint-up(sm) {
                    padding: 0 rem-calc(32) rem-calc(24);
                }

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: 0;
                    width: calc(100% - #{rem-calc(32)});
                    left: rem-calc(16);
                    height: rem-calc(1);
                    background: $warm-grey-02;

                    @include media-breakpoint-up(sm) {
                        width: calc(100% - #{rem-calc(64)});
                        left: rem-calc(32);
                    }
                }

                .cc-content-icon {
                    position: relative;

                    .cc-find-in-store-input {
                        margin: 0;
                        padding-right: rem-calc(90);
                    }

                    .btn-storelocator-search {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: rem-calc(13);
                        line-height: rem-calc(18);
                        font-weight: $primary-font-medium;
                        right: rem-calc(12);
                        text-transform: capitalize;
                        border-bottom: rem-calc(1) solid $black;

                        &:hover {
                            @include media-breakpoint-up(md) {
                                color: $red;
                                border-bottom: rem-calc(1) solid $red;
                            }
                        }
                    }
                }

                .invalid-feedback {
                    margin: rem-calc(10) rem-calc(210) 0;
                }
            }

            .cc-modal-body {
                padding: 0 rem-calc(16) rem-calc(24);

                @include media-breakpoint-up(sm) {
                    padding: 0 rem-calc(32) rem-calc(24);
                }

                .no-results {
                    display: block;
                    font-size: rem-calc(13);
                    line-height: rem-calc(18);
                    font-weight: $primary-font-bold;
                    text-align: left;

                    @include media-breakpoint-up(sm) {
                        font-size: rem-calc(15);
                        line-height: rem-calc(22);
                    }
                }
            }

            .card-body {
                display: block;
                position: relative;
                margin: 0 0 rem-calc(8);
                padding: rem-calc(16) rem-calc(12);
                width: 100%;
                background: $warm-grey-01;

                @include media-breakpoint-up(sm) {
                    padding: rem-calc(16);
                }

                .store-title {
                    display: block;
                    font-weight: $primary-font-medium;
                    font-size: rem-calc(15);
                    line-height: rem-calc(22);
                    letter-spacing: rem-calc(0.15);
                    color: $black;
                    text-align: left;
                    padding-right: rem-calc(70);
                    margin: 0 0 rem-calc(16);

                    a {
                        &:hover {
                            @include media-breakpoint-up(md) {
                                color: $red;
                            }
                        }
                    }
                }

                .store-distance {
                    display: block;
                    font-weight: $primary-font-regular;
                    font-size: rem-calc(15);
                    line-height: rem-calc(22);
                    letter-spacing: rem-calc(0.13);
                    color: $warm-grey-04;
                    text-align: right;
                    position: absolute;
                    top: rem-calc(16);
                    right: rem-calc(12);

                    @include media-breakpoint-up(sm) {
                        right: rem-calc(16);
                    }
                }

                .cc-address-wrp {
                    margin: 0;
                    font-weight: $primary-font-regular;
                    font-size: rem-calc(13);
                    line-height: rem-calc(18);
                    letter-spacing: rem-calc(0.13);
                    color: $black;
                    text-align: left;
                    margin: 0;

                    @include media-breakpoint-up(sm) {
                        display: flex;
                    }
                }

                .cc-address-container {
                    display: block;
                    font-weight: $primary-font-regular;
                    font-size: rem-calc(13);
                    line-height: rem-calc(18);
                    letter-spacing: rem-calc(0.13);
                    color: $black;
                    text-align: left;
                    margin: 0 0 rem-calc(12);

                    @include media-breakpoint-up(sm) {
                        width: 50%;
                        flex: 0 0 50%;
                        padding-right: rem-calc(12);
                        margin: 0;
                    }
                }

                .cc-advanced-info-container {
                    .cc-phone {
                        display: inline-block;
                        margin-bottom: rem-calc(12);

                        @include media-breakpoint-up(sm) {
                            margin-bottom: rem-calc(8);
                        }

                        a {
                            display: inline-block;
                            border-bottom: rem-calc(1) solid $black;

                            &:hover {
                                @include media-breakpoint-up(md) {
                                    color: $red;
                                    border-bottom: rem-calc(1) solid $red;
                                }
                            }
                        }
                    }
                }
            }

            .modal-footer {
                border: 0;
                padding: 0 rem-calc(16) rem-calc(24);

                @include media-breakpoint-up(sm) {
                    padding: 0 rem-calc(32) rem-calc(24);
                }

                .cc-load-more {
                    width: 100%;
                    margin: 0;
                }
            }
        }
    }

    .cc-modal-add-to-cart {
        &.modal.modal-bottom {
            .modal-dialog {
                position: fixed;
                bottom: 0;
                left: 0;
                right: 0;
                margin: 0;
                width: 100%;
                max-width: none;
                transition: transform 0.3s ease-out;
                transform: translateY(100%);
            }

            .modal-header {
                display: flex;
                flex-direction: column;
                align-items: start;
                border: none;
                font-weight: $primary-font-semibold;
            }
        }

        &.modal.modal-bottom.show {
            .modal-dialog {
                transform: translateY(0);
            }
        }

        .modal-header {
            padding-bottom: 0;
        }

        .modal-content {
            border-radius: rem-calc(8) rem-calc(8) 0 0;
            border: none;

            .cc-pdp-product-name {
                display: block;
                font-size: rem-calc(18);
                font-weight: 500;
                line-height: rem-calc(24);
                margin-bottom: rem-calc(7);
                padding-right: rem-calc(32);
            }

            .cc-size-label {
                display: block;
                font-size: rem-calc(12);
                line-height: rem-calc(18);
                letter-spacing: 0.1em;
                color: $warm-grey-05;
                margin: 0 0 rem-calc(13);
                font-weight: $primary-font-medium;
                text-transform: uppercase;

                &__number {
                    color: $black;
                }

                span {
                    @include media-breakpoint-up(lg) {
                        display: none;
                    }
                }
            }

            .cc-size-ul-list {
                display: flex;
                flex-wrap: wrap;
                gap: rem-calc(5);
                margin-right: rem-calc(-4);

                .cc-single-size-tile {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: rem-calc(1) solid $warm-grey-02;
                    border-radius: rem-calc(2);
                    cursor: pointer;
                    margin: 0;
                    height: rem-calc(40);
                    font-weight: $primary-font-medium;
                    font-size: rem-calc(15);
                    line-height: rem-calc(22);
                    padding: 0 0.4rem;
                    width: calc(33.333333% - #{rem-calc(4)});

                    @include media-breakpoint-up(lg) {
                        width: calc(25% - #{rem-calc(4)});
                    }

                    &:hover {
                        @include media-breakpoint-up(lg) {
                            color: $red;
                        }
                    }

                    &.selected {
                        border-color: $black;
                        position: relative;
                    }

                    &.unselectable {
                        border-color: $grey2;
                        color: $grey2;
                        cursor: not-allowed;
                        position: relative;
                    }

                    &.low-stock {
                        position: relative;
                        &::after {
                            background-color: $red;
                            border-radius: rem-calc(2);
                            bottom: rem-calc(9);
                            content: '';
                            height: rem-calc(4);
                            position: relative;
                            width: rem-calc(4);
                            display: inline-block;
                        }
                    }

                    .frac {
                        font-feature-settings: "frac";
                        padding-left: 3px;
                    }
                }
            }
        }
    }

    .cc-zoom-target {
        background: $white;
        display: none;
        height: 100%;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 10;

        .pswp__bg {
            background: $white;
        }

        .pswp__top-bar {
            background-color: transparent;
            opacity: 1;
        }

        .pswp__button--close {
            height: auto;
            position: absolute;
            right: rem-calc(40);
            top: rem-calc(40);
            transition: all 300ms;
            width: auto;

            &:hover {
                color: $red1;
                transform: rotate(90deg);
                transition: all 300ms;
            }

            .icon-close-remove {
                font-size: rem-calc(21);
            }
        }

        .pswp__caption {
            display: none;
        }

        .carousel-thumbnails {
            display: none;
            left: rem-calc(40);
            position: absolute;
            top: rem-calc(40);
            z-index: 10000;

            @include media-breakpoint-up(sm) {
                display: block;
                max-height: calc(100% - 80px);
                overflow-y: auto;
            }

            ul {
                display: flex;
                flex-direction: column;

                .carousel-thumbnails-item {
                    cursor: pointer;

                    img {
                        margin-bottom: rem-calc(20);
                        width: rem-calc(80);
                    }
                }
            }
        }

        .pswp-slick-dots {
            bottom: rem-calc(30);
            display: none;
            z-index: 10000;

            @include media-breakpoint-down(xs) {
                display: block;
            }

            li {
                background-color: $grey2;
                border-radius: rem-calc(50);
                height: rem-calc(12);
                width: rem-calc(12);

                @include media-breakpoint-down(xs) {
                    height: rem-calc(8);
                    width: rem-calc(8);
                    margin: 0 rem-calc(2);
                }

                button {
                    &::before {
                        content: '';
                    }
                }

                &.slick-active {
                    background-color: $black;
                }
            }
        }

        .cc-zoom-label {
            cursor: zoom-in;
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 10000;

            &:hover {
                background-color: $black;
                color: $white;
            }
        }
    }

    //IN-AR TRY ON
    .cc-modal-try-on-ar {

        background-color: rgba(0, 0, 0, 0.75);
        backdrop-filter: blur(5px);
        color: white;

        .modal-dialog {
            background-color: transparent;
            max-width: rem-calc(650);

            @include media-breakpoint-up(md) {
                max-width: rem-calc(995);
            }
        }

        .modal-content {
            border-radius: 0;
            border: 0;
            background-color: transparent;
            margin: rem-calc(20) auto rem-calc(20);

            .modal-header {
                border: 0;
                padding: 0  rem-calc(20);
                padding-top: rem-calc(50);

                @include media-breakpoint-up(md) {
                    padding: 0 rem-calc(70);
                    padding-top: rem-calc(50);
                }

                .modal-title {
                    font-size: rem-calc(20);
                    font-weight: $primary-font-semibold;
                    margin-bottom: rem-calc(30);
                    position: relative;
                    text-align: center;
                    text-transform: uppercase;
                    width: 100%;

                    &::after {
                        background: $red1;
                        bottom: 0;
                        content: '';
                        display: block;
                        height: 1px;
                        left: 50%;
                        margin-left: rem-calc(-20);
                        position: absolute;
                        width: rem-calc(40);
                    }
                }

                .close {
                    position: absolute;
                    right: rem-calc(16);
                    top: rem-calc(16);
                    padding: 20px;
                    opacity: 1;

                    &:hover {
                        opacity: 0.7;
                    }

                    .icon-close-remove {
                        font-size: 21px;

                        &::before {
                            color: white;
                        }
                    }
                }

            }

            .modal-body {
                border: 0;
                margin: 0 auto rem-calc(32) auto;

                .cc-qr-code-container {
                    width: rem-calc(250);
                    height: rem-calc(250);
                    width: rem-calc(300);
                    height: rem-calc(300);
                    border: rem-calc(10) solid black;
                    border-radius: rem-calc(10);
                    padding: rem-calc(10);
                    background-color: white;

                    .cc-qr-code {
                        width: 100%;
                    }
                }
            }

        }

        .cc-try-on-ar__description {
            font-size: rem-calc(14);
            margin-bottom: rem-calc(4);
            padding: 0 rem-calc(20);
            text-align: center;

            @include media-breakpoint-up(md) {
                padding: 0 rem-calc(210);
            }
        }
    }
}

.cc-pdp-col-description {

    .cc-centering-price {
        display: block;
        width: fit-content;
    }

    .cc-price-after-message {
        color: $warm-grey-05;
        display: block;
        font-size: rem-calc(10);
        line-height: rem-calc(14);
        letter-spacing: rem-calc(1);
        font-style: normal;
        font-weight: 800;
        padding-left: 0;
        padding-right: 0;
        text-transform: uppercase;
    }
}

.cc-shoe-size-measurement {
    text-decoration: underline;

    &:last-child {
        display: none;
    }

    .cc-shoe-size-measurement-link {
        color: $red1;
        display: block;
    }
}

.cc-price-club {
    color: $red1;
    font-size: rem-calc(25);
    font-style: italic;
    font-weight: 500;
    margin: rem-calc(10) 0;

    .cc-price-club-title {
        font-size: rem-calc(16);
        text-transform: uppercase;
    }

    .cc-price-club-description,
    .cc-price-club-link {
        display: block;
        font-size: rem-calc(14);
        font-style: normal;
        font-weight: 400;
    }

    .cc-price-club-link {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

.cc-label-clubprice {
    &::after {
        color: $red1;
        display: inline-block;
        font-family: iconfont;
        font-size: rem-calc(64);
        font-style: normal;
        font-variant: normal;
        font-weight: 400;
        line-height: 1;
        speak: none;
        text-decoration: none;
        text-transform: none;
        vertical-align: baseline;
    }
}

.cc-pdp {
    &__logo {
        width: auto;
        height: rem-calc(20);
    }

    &__description {
        display: block;
        font-size: rem-calc(22);
        line-height: rem-calc(22);
        letter-spacing: rem-calc(0.28);
        margin-top: rem-calc(20);

        &__article {
            font-size: rem-calc(12);
            font-weight: 500;
            line-height: rem-calc(18);
            letter-spacing: rem-calc(1.2);
            text-transform: uppercase;
            margin-top: rem-calc(18);
            color: $warm-grey-04;
        }
    }

    &__features {
        margin-top: rem-calc(38);
        padding-top: rem-calc(40);
        border-top: rem-calc(1) solid $warm-grey-04;

        &:not(.d-none) + .cc-pdp__links {
            border: 0;
            padding-top: 0;
        }

        &__ul {
            li {
                display: list-item;
                list-style: disc;
                margin-left: rem-calc(18);
                font-size: rem-calc(15);
                line-height: rem-calc(22);
                letter-spacing: rem-calc(0.15);
            }
        }

        &__keyBenefits:not(.d-none) {
            + .cc-pdp__features__ul {
                margin-top: rem-calc(24);
            }
        }
    }

    &__links {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: rem-calc(8);
        margin-top: rem-calc(38);
        padding-top: rem-calc(40);
        border-top: rem-calc(1) solid $warm-grey-04;

        &__withoutDescription {
            padding-top: 0;
            border-top: 0;
        }
    }

    &__link {
        font-size: rem-calc(15);
        font-weight: 500;
        line-height: rem-calc(22);
        letter-spacing: rem-calc(0.15);
        border: rem-calc(1) solid $warm-grey-02;
        border-radius: rem-calc(2);
        a {
            display: block;
            padding: rem-calc(7) rem-calc(16);
            cursor: pointer;
        }

        &:hover {
            a {
                color: $red;
            }
        }
    }

    &__tooltip {
        @include animation();
        opacity: 0;
        z-index: -1;
        position: absolute;
        left: rem-calc(-62);
        top: 145%;
        font-size: rem-calc(13);
        font-weight: 700;
        line-height: rem-calc(18);
        padding: rem-calc(12) rem-calc(16);
        border-radius: rem-calc(2);
        background: $white;
        width: rem-calc(129);
        filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.20));

        @include media-breakpoint-down(md) {
            display: none;
        }

        &:before {
            content: '';
            position: absolute;
            top: -8px;
            left: 50%;
            width: 0;
            height: 0;
            border-left: rem-calc(8) solid transparent;
            border-right: rem-calc(8) solid transparent;
            border-bottom: rem-calc(8) solid $white;
        }

        .cc-add-to-wish-list:hover & {
            opacity: 1;
            z-index: 2;
        }
    }

    &__stickyBtn {
        &__wrapper {
            @include animationSlow();

            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: $white;
            box-shadow: 0px -4px 6px 0px rgba(0, 0, 0, 0.10);
            border-top-right-radius: rem-calc(6);
            border-top-left-radius: rem-calc(6);
            padding: rem-calc(16);
            z-index: 3;
            transform: translateY(0);

            @include media-breakpoint-up(md) {
                display: none;
            }

            &__scrollDown {
                transform: translateY(200px);
            }
        }
    }

    &--accordion {
        &--icon {
            width: rem-calc(16);
            height: rem-calc(16);
        }
    }

    &--social {
        &--icon {
            width: rem-calc(20);
            height: rem-calc(20);
        }
    }

    &__productCare,
    &__return,
    &__delivery {
        &__title {
            display: flex;
            flex-direction: row;
            align-items: end;
            gap: rem-calc(8);
            margin-top: rem-calc(9);

            h3 {
                font-size: rem-calc(13);
                font-weight: 700;
                line-height: rem-calc(18);
            }

            img {
                width: rem-calc(32);
                height: rem-calc(32);
                object-fit: contain;
            }
        }
    }

    &__productCare {
        &__title {
            margin-top: 0;

            img {
                width: rem-calc(24);
                height: rem-calc(28);
                object-fit: contain;
            }
        }

        &__accordion {
            p {
                margin-bottom: rem-calc(3);
            }

            a {
                text-underline-offset: rem-calc(5);
                margin-bottom: rem-calc(11);
            }
        }
    }

    &__return {
        &__title {
            margin-top: rem-calc(24);
            padding-top: rem-calc(24);
            border-top: rem-calc(1) dashed $warm-grey-04;
        }
    }

    &__delivery {
        @include media-breakpoint-down(md) {
            margin-top: 0;
        }
    }

    &__accordion {
        &__delivery {
            margin-top: rem-calc(11);
        }
    }

    &__codigo {
        margin-left: rem-calc(18);
    }

    &__shippingMsg {
        color: $ruby-red;
        font-size: rem-calc(13);
        font-weight: 500;
        line-height: rem-calc(18);
        letter-spacing: rem-calc(0.13);

        .html-slot-container {
            margin-bottom: rem-calc(26);

            @include media-breakpoint-down(md) {
                margin-bottom: rem-calc(20);
            }
        }
    }
}

.cc-image-overlay {
    padding-right: 0!important;
    .modal-dialog,
    .modal-content {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        max-width: 100%;
        max-height: 100%;
        border: none;
    }

    &__extra-wrp {
        width: 100%;
        height: 100%;
        margin: 0 auto;
    }

    &__close {
        position: absolute;
        top: 0;
        right: 0;
        width: rem-calc(56);
        height: rem-calc(56);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        color: $black;

        &:hover {
            @include media-breakpoint-up(md) {
                color: $red;
            }
        }
    }

    &__wrp {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
        background: $cold-grey-01;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .slick-arrow {
            opacity: 0;
        }
    }

    &__main-carousel {
        width: 100%;

        @include media-breakpoint-up(md) {
            max-width: rem-calc(906);
            margin: 0 auto;
            max-height: 100%;
        }

        &:not(.slick-initialized) {
            opacity: 0;
        }

        .cc-pdp-carousel-item {
            position: relative;
            display: block;
            z-index: 1;
            width: 100%;

            &:not(.video) {
                cursor: zoom-in;
                cursor: url('../images/pdp-overlay-zoom.svg'), auto;
            }

            iframe {
                pointer-events: none;
                aspect-ratio: 1/1;
            }
        }

        .cc-container-dis-picture {
            aspect-ratio: 1;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;

                @include media-breakpoint-up (medium) {
                    height: 100dvh;
                    object-fit: contain;
                }
            }
        }
    }

    &__thumb {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        padding: rem-calc(12) rem-calc(37);

        @include media-breakpoint-up(md) {
            width: rem-calc(128);
            bottom: rem-calc(32);
            padding: 0 rem-calc(24);
        }

        .slick-list {
            padding: 0 !important;
        }

        &:not(.slick-initialized) {
            opacity: 0;
        }

        .cc-pdp-carousel-item {
            width: 100%;
            opacity: 0.5;
            cursor: pointer;

            iframe {
                pointer-events: none;
                aspect-ratio: 1/1;
            }

            &:hover {
                opacity: 1;
            }
        }

        .slick-current {
            .cc-pdp-carousel-item {
                opacity: 1;
            }
        }

        .cc-container-dis-picture {
            aspect-ratio: 1;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}
